.main_canvas {
    display: flex;
    justify-content: center;
}

.main_canvas button{
    max-height: 50px;
    margin: 11rem 0.5rem 0 0;
}

.drawingCanvas{
    outline: black 3px solid;
    background-color: beige;
}

.previewCanvas{
    flex-direction: column;
    margin-left: 0.5rem;
}

.NNcanvas{
    outline: black 3px solid;
    background-color: rgb(206, 180, 184);
    /* max-height: 100px;
    max-width: 100px; */
}